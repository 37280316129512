.ant-picker-header button {
  color: unset !important;
}

.ant-picker-panel-container {
  background: white !important;
}
.ant-picker-cell {
  color: unset !important;
}

.react-tel-input .form-control {
  height: 40px !important;
  width: 100% !important;
  font-size: medium !important;
  border-radius: 2px !important;
}

.react-tel-input .form-control:hover,
.react-tel-input .form-control:focus {
  outline: #57aef0 solid 1px;
  border: 0 !important;
}

div.ant-tabs-content.ant-tabs-content-animated.ant-tabs-top-content > div.ant-tabs-tabpane.ant-tabs-tabpane-active > div > div:nth-child(1) > div > span > i {
  background-color: #7bad7e;
}
