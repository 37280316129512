
.general-availability-list {
  padding: 2px 2px 1px 2px !important;
  margin-top: 15px !important;
}

.off-availability-header,
.special-availability-header,
.general-availability-header {
  display: flex;
  justify-content: space-between;
}

.general-availability-list .ant-list-item {
  padding: 10px 15px 10px 15px !important;
  margin-bottom: 1px;
  background-color: #f0f2f5;
}

.general-availability-list .ant-list-item h3 {
  margin-bottom: 0px;
  width: 300px;
}

.general-availability-button {
  display: flex;
  margin-top: 15px;
  justify-content: flex-end;
  height: 50px;
}

.general-availability-button button {
  width: fit-content;
}

.general-availability-button :first-child {
  margin-right: 20px;
}

.general-availability-list .ant-picker-large .ant-picker-input > input {
  font-size: 16px;
  font-weight: 600;
}

.general-availability-button-add {
  height: 35px;
  margin-top: 5px;
}

@media screen and (max-width: 500px) {
  .general-availability-list .ant-list-item h3 {
    font-size: 14px;
  }

  .general-availability-button {
    justify-content: center;
    flex-direction: column;
    height: 90px;
  }

  .general-availability-button :first-child {
    margin-right: 0px;
  }

  .general-availability-button button {
    width: 100%;
  }
  .off-availability-header,
  .special-availability-header,
  .general-availability-header {
    flex-direction: column;
  }
}
